#background-wrap {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width:auto !important;
  padding: 20px !important;
  border-radius: 7px;
  text-align: center;
  color: red;
}
.popup-content .close {
  border: 0;
  width: 20%;
  padding-inline: 10px;
  padding-block: 7px;
  color: white;
  background-color: #012970;
  border-radius: 7px;
}
