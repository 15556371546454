.studentsTable > div {
  border-radius: 10px;
  padding: 0;
}
.studentsTable {
  margin-top: 3rem;
}
.studentsTable .table-container {
  max-height: 550px;
  overflow-y: scroll;
}
.studentsTable .table-container thead th {
  position: sticky;
  top: 0;
}
@media (max-width: 1620px) {
  .studentsTable .table-container {
    max-height: 380px;
    overflow-y: scroll;
  }
}
@media (min-width: 2400px) {
  .studentsTable .table-container {
    max-height: 720px;
    overflow-y: scroll;
  }
}
.studentsTable th {
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  background-color: #012970;
  padding: 1.56rem;
}
.studentsTable td {
  padding: 1.56rem;
}

.table-container {
  border-radius: 10px;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr.even {
  background-color: #ffffff;
}

tr.odd {
  background-color: #e3f2fd;
}
.student-record {
  border-left: 6px solid #012970;
  border-bottom: 6px solid #012970;
}
.student-record .card-inner {
  border-radius: 8px;
  border: 3px solid #012970;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.studentInfoHeadline {
  font-size: 1rem;
  font-weight: 600;
  color: #012970;
}
.checkStudentInfo input,
.checkStudentInfo .multi-select {
  border: none;
  padding: 0.7rem;
  width: 23rem;
  background-color: white;
  border-radius: 8px;
  margin-right: 0.9rem;
  margin-bottom: 0.9rem;
  color: black;
  font-weight: normal;
}
.css-13cymwt-control {
  border: 0 !important;
}
.student-record {
  height: 70vh;
}
.student-record p {
  font-size: 1.5rem;
  font-weight: 500;
}
.student-record .studentName {
  font-size: 3rem !important;
  text-transform: capitalize;
  color: #012970;
  font-weight: 500;
}
#className {
  font-size: 1.5rem;
  font-weight: 500;
}
.enterBtn{
  border: 0;
  width: 10rem;
  padding-inline: 20px;
  padding-block: 8px;
  background: #012970;
  color: white;
  border-radius: 8px;
  margin-top: -0.5rem;
}
