.collectFee input,
select {
  border: none;
  padding: 0.7rem;
  width: 23rem;
  background-color: white;
  border-radius: 8px;
  margin-right: 0.9rem;
  margin-bottom: 0.9rem;
  color: black;
  font-weight: normal;
  border:2px solid #012970;
  /* //border: 2px solid #4154f1; */
}
.collectFee button{
  width: 10rem;
  padding: 0.7rem;
  border-radius: 10px;
  background: #012970;
  color: #fff;
  border: 0;
}
.studentIdHeadline {
  color: #012970;
  font-weight: 500;
}
.studentName {
  font-weight: 600;
  color: #012970;
  font-size: 1.2rem;
}
.studentRecordCard {
  border-left: 6px solid #012970;
  border-bottom: 6px solid #012970;
}
.card-inner-container {
  border: 2px solid #012970;
  border-radius: 5px;
}
.fieldName {
  font-size: 1.1rem;
  font-weight: 600;
}
.fieldDetail {
  font-size: 1rem;
  margin-top: -7px;
}
.loadMore {
  width: 10rem;
  border-radius: 10px;
  background: #012970;
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
#partialPaid {
  background: teal;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
#paid {
  background: #70BF4C;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
#notPaid {
  background: red;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
#advancePaid{
  background: #012970;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
#dueAmount{
  background: red;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
#paidAmmount{
  background: #70BF4C;
  color: white;
  width: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}