/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  
  margin-bottom: 100px;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}
